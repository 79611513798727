<!--
 * @Author: clc frank.clc@qq.com
 * @Date: 2023-12-07 18:55:01
 * @LastEditors: clc frank.clc@qq.com
 * @LastEditTime: 2023-12-07 19:15:50
 * @FilePath: \lanm\src\views\huodong202312.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <header-nav></header-nav>
    <img src="@/assets/images/huodong202312.jpg" alt="" style="width: 100%;" />
    <floor></floor>
  </div>
</template>

<script>
import HeaderNav from "@/components/Header.vue";
import Floor from "@/components/Floor.vue";

export default {
  components: {
    HeaderNav,
    Floor
  },
  data: () => ({


  }),
}
</script>

<style></style>